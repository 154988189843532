<template>
  <a-row :gutter="25">
    <a-col :xxl="24" :lg="24" :md="24" :xs="24" style="margin-top: 2rem;">
      <template #default>
        <sdCards headless>
          <a-row :gutter="25">
            <a-col :xxl="18" :lg="17" :md="28" :xs="24">
              <a-form name="sDash_validation-form" layout="vertical">
                <a-row :gutter="25">
                  <a-col :md="24" :xs="24">
                    <h3 class="title-bold-normal">Generar un reporte</h3>
                  </a-col>
                </a-row>
                <br />
                <a-row :gutter="25">
                  <a-col :md="24" :xs="24">
                    <a-form-item label="Buscar (CURP, nombre, folio o correo electrónico)" class="title-normal">
                      <a-input
                        class="title-normal input-field"
                        placeholder="Buscar participante"
                        v-on:keyup.enter="getDataFromPaginatorNumber(1)"
                        v-on:change="handleChangeSearch($event)"
                        :disabled="dataForm.tableLoading"
                      >
                        <template #prefix>
                          <sdFeatherIcons type="search" size="14" />
                        </template>
                      </a-input>
                    </a-form-item>
                  </a-col>
                </a-row>
              </a-form>
            </a-col>
          </a-row>
        </sdCards>
      </template>
    </a-col>
    <a-col :xxl="24" :lg="24" :md="24" :xs="24">
      <Suspense>
        <template #default>
          <sdCards headless>
            <a-row :gutter="25">
              <a-col :xxl="16" :lg="12" :md="12" :sm="24" :xs="24">
                <h3 class="title-bold-normal">{{ coreDataForm.eventData.title }}</h3>
              </a-col>
              <a-col :xxl="8" :lg="12" :md="12" :sm="24" :xs="24">
                <div class="text-right">
                  <Button v-on:click="openModal('varios')" class="btn-delete-various title-normal mr-2"
                    v-if="roles[0].nombre.toLowerCase() === 'administrador general'
                          || removeAccents(roles[0].nombre).toLowerCase() === 'administrador de area' && coreDataForm.participantsData.length > 0"
                  >
                    <img
                      :src="require(`@/static/img_events/Eventos_eliminar varios.svg`)"
                      alt="logo"
                      height="24"
                      width="24"
                      class="mr-1 ml-2"
                    />
                    Eliminar varios
                  </Button>
                  <a-col :xxl="24" :lg="24" :md="24" :xs="24" v-if="dataForm.reportLoading">
                    <a-spin size="small" />
                  </a-col>
                  <Button v-else v-on:click="downloadReport()" class="btn-add-event title-normal mr-2">
                    <img
                      :src="require(`@/static/img_events/SEJ_Eventos_excel.svg`)"
                      alt="logo"
                      height="15"
                      width="15"
                      class="mr-1 ml-2"
                    />
                    Descargar excel
                  </Button>
                </div>
              </a-col>
            </a-row>
            <a-row :grutter="25" v-if="!coreDataForm.showTableByRequest">
              <a-col :xxl="24" :lg="24" :md="24" :xs="24">
                <a-spin size="small" style="position:relative; left: 45%;margin-top: 2rem;" />
              </a-col>
            </a-row>
            <a-row :grutter="25" v-if="coreDataForm.showTableByRequest">
              <a-col :xxl="24" :lg="24" :md="24" :xs="24" v-if="dataForm.tableLoading">
                <a-spin size="small" style="position:relative; left: 45%;margin-top: 2rem;" />
              </a-col>
              <a-col :xxl="24" :lg="24" :md="24" :xs="24" v-else>
                <DataTable
                  :columns="roles[0].nombre.toLowerCase() == 'administrador general'
                            || roles[0].nombre.toLowerCase() == 'responsable de evento'
                            || removeAccents(roles[0].nombre).toLowerCase() == 'administrador de area' ? participantsTableColumns : participantsTableColumnsCCT"
                  :data="roles[0].nombre.toLowerCase() == 'administrador general'
                          || roles[0].nombre.toLowerCase() == 'responsable de evento'
                          || removeAccents(roles[0].nombre).toLowerCase() == 'administrador de area' ? dataSource : coreDataForm.participantsData"
                  :totalRecords="dataForm.totalRecords"
                  v-on:getCurrentPaginator="getDataFromPaginatorNumber"
                />
              </a-col>
            </a-row>
          </sdCards>
        </template>
        <template #fallback>
          <sdCards headless>
            <a-skeleton active />
          </sdCards>
        </template>
      </Suspense>
    </a-col>
  </a-row>
  <sdModal
    type="primary"
    :width="350"
    :onOk="handleOk"
    :visible="dataModal.visible"
    :onCancel="handleCancel"
  >
    <div>
      <p class="title-modal mt-3">{{ dataModal.title }}</p>
    </div>
    <div v-if="dataModal.loading" class="modal-buttons">
      <a-spin size="small" />
    </div>
    <div v-else class="modal-buttons">
      <button class="btn-cancel" @click="handleCancel">Cancelar</button>
      <button class="btn-accept" @click="handleDeleteParticipant">Aceptar</button>
    </div>

  </sdModal>
</template>
<script>
import { defineAsyncComponent, reactive, h, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { getParticipants, getParticipantsReport, getEvent, deleteParticipants } from '@/services/events';
import { Notification } from 'ant-design-vue';
import { getItem } from '@/utility/localStorageControl'
import { removeAccents } from '@/utility/utility'

const DataTable = defineAsyncComponent(() => import('../../../components/DataTable/DataTable.vue'));

const ParticipantsComponent = {
  name: 'ResgisterComponent',
  components: {
    DataTable,
  },
  props: {},
  watch: {},
  async setup() {
    const iconActions = require('@/static/img_events/SEJ_Eventos_opciones-21-2.svg');
    const iconDeleteParticipant = require('@/static/img_events/Eventos_eliminar_participante.svg');
    const iconShowForm = require('@/static/img_events/SEJ_Eventos_detalles-gris.svg');
    const roles = getItem("roles");
    const router = useRouter();
    roles[0].nombre = roles[0].nombre.normalize('NFD')
     .replace(/([aeio])\u0301|(u)[\u0301\u0308]/gi,"$1$2")
     .normalize();

    const generateObjectDataTable = assistants => {
      let data = [];
      data = assistants.map(element => {
        return {
          key: element.id,
          ...element.user,
          ...element,
        };
      });
      return data;
    };
    const idEvent = useRoute().params.id;
    const getPartipantsInfo = async (numerPage, search = null) =>{
      let assistants = [];
      await getParticipants(idEvent, numerPage, search).then((response) => {
        if (response.success) {
          assistants = generateObjectDataTable(response.assistants.data);
          dataForm.totalRecords = response.assistants.total;
        }
      })
      return assistants;
    }

    const getEventDetail = async () => {
      let event = {};
      await getEvent(idEvent).then((response) => {
        if (response.success) {
          event = response.event
        }
      })
      .catch((e)=>{
        console.log(e)
      })
      return event;
    }

    const handleDeleteParticipant = async () => {
      dataForm.tableLoading = true;
      await deleteParticipants(idEvent, coreDataForm.selectedParticipants)
        .then(async (response) => {
          if(response.success) {
            Notification['success']({
              message: 'Exito',
              description: response.message
            })
            coreDataForm.participantsData = await getPartipantsInfo(1);
          } else {
            Notification['error']({
              message: 'Error',
              description: response.message || 'Hubo un error al eliminar'
            })
          }
        })
        .catch(() => {
          Notification['error']({
            message: 'Error',
            description: 'Hubo un error al eliminar'
          })
        })
      coreDataForm.selectedParticipants = [];
      dataModal.visible = false;
      dataForm.tableLoading = false;
    }

    const dataForm = reactive({
      searchText: "",
      totalRecords: 0,
      tableLoading: false,
      reportLoading: false,
    });

    const coreDataForm = reactive({
      participantsData: await getPartipantsInfo(1),
      eventData: await getEventDetail(),
      showTableByRequest: true,
      selectedParticipants: [],
    })

    const dataModal = reactive({
      visible: false,
      title: ''
    });

    const dataSource = computed(
      () =>
        coreDataForm.participantsData.length &&
        coreDataForm.participantsData.map(value => {
          const { id, user_id, folio, curp, name, email, assistant, status } = value;
          return {
            key: id,
            checked: roles[0].nombre.toLowerCase() === 'responsable de evento' ? '' : h(
              <a-checkbox></a-checkbox>,
              {
                onClick: () => rowSelection(user_id, event)
              }
            ),
            folio: <span class="date-started">{folio}</span>,
            curp: <span class="date-finished">{curp}</span>,
            name: <span>{name}</span>,
            email: <span>{email}</span>,
            type: <span>{assistant ? 'Asistente' : 'Participante'}</span>,
            status: <span>{status}</span>,
            actions: status == 'Activo' && !(roles[0].nombre.toLowerCase() === 'responsable de evento' && !coreDataForm.eventData.activities) ? (
              <sdDropdown
                action="click"
                class="dropdown-participants"
                overlay={(h(
                  'div',
                  {
                    class: 'dropdown-actions'
                  },
                  [
                    coreDataForm.eventData.activities && !assistant ? h(
                      'button',
                      {
                        onClick: () => sendViewForm(id),
                        class: 'btn-dropdown'
                      },
                      [
                        h(
                          'img',
                          {
                            class: 'btn-dropdown_logo',
                            src: iconShowForm
                          }
                        ),
                        'Ver formulario'
                      ]
                    ) : '',
                    status && roles[0].nombre.toLowerCase() !== 'responsable de evento' ? h(
                      'button',
                      {
                        onClick: () => openModal(assistant, user_id),
                        class: 'btn-dropdown'
                      },
                      [
                        h(
                          'img',
                          {
                            class: 'btn-dropdown_logo',
                            src: iconDeleteParticipant,
                          }
                        ),
                        `Eliminar ${assistant ? 'asistente' : 'participante'}`
                      ]
                    ) : ''
                  ]
                ))
                }
              >
                <img class="btn-dropdown-actions" src={iconActions} alt="Acciones" />
              </sdDropdown>
            ): '',
          };
        }),
    );

    const rowSelection = (idParticipant, event) => {
      if (event.target.checked) {
        coreDataForm.selectedParticipants.push(idParticipant)
      } else {
        const index = coreDataForm.selectedParticipants.findIndex((element) => element === idParticipant)
        coreDataForm.selectedParticipants.splice(index, 1)
      }
    }

    const openModal = (type, idParticipant) => {
      if (type === 'varios') {
        dataModal.title = '¿Estás seguro de eliminar participantes/asistentes de este evento?';
      } else {
        dataModal.title = `¿Estás seguro que desea eliminar a este ${type ? 'asistente' : 'participante'} del evento?`;
        coreDataForm.selectedParticipants.push(idParticipant);
      }
      dataModal.visible = true;
    }

    const handleOk = () => {
      dataModal.visible = false;
    };

     const handleCancel = () => {
      dataModal.visible = false;
    }

    const handleChangeSearch = e => {
      dataForm.searchText = e.target.value;
    };

    const getDataFromPaginatorNumber = async number => {
      dataForm.tableLoading = true;
      coreDataForm.participantsData = await getPartipantsInfo(number, dataForm.searchText);
      dataForm.tableLoading = false;
    };

    const downloadReport = async () => {
      const search = dataForm.searchText || null;
      dataForm.reportLoading = true;
      await getParticipantsReport(idEvent, search).then((response) => {
        if (response && response.success !== false) { //Si es blob no tiene propiedad success, si falla success es false
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `Reporte_asistentes_${idEvent}.xlsx`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      } else {
        Notification.error({
          message: "Error",
          description: "Ocurrió un error al descargar el archivo",
        });
      }
      }).catch(() => {
        Notification.error({
          message: "Error",
          description: "Ocurrió un error al descargar el archivo",
        });
      });
      dataForm.reportLoading = false;
    }

    const sendViewForm = (idParticipant) => {
      router.push({name: 'answers', params: {id: idParticipant}})
    }

    const participantsTableColumnsCCT = [
      {
        title: 'Folio',
        dataIndex: 'folio',
        key: 'folio',
      },
      {
        title: 'CURP',
        dataIndex: 'curp',
        key: 'curp',
      },
      {
        title: 'Nombre completo',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Correo electrónico',
        dataIndex: 'email',
        key: 'email',
      },
      {
        title: 'Tipo',
        dataIndex: 'type',
        key: 'type',
      },
      {
        title: 'Estatus',
        dataIndex: 'status',
        key: 'status',
      },
      coreDataForm.eventData.activities ? {
        title: 'Formulario',
        dataIndex: 'form',
        key: 'form',
        customRender({ record }) {
          return [
            //INICIO::button 1
            !record.assistant ? h(
              'button',
              {
                onClick: () => sendViewForm(record.id),
                style: { background: 'none', cursor: 'pointer', border: 'none' }
              },
              [
                '',
                h('img', {
                  src: require(`@/static/img_events/SEJ_Eventos_detalles.svg`),
                  style: { width: '22px' },
                }),
              ],
            ) : '',
            //FIN::button 1
          ];
        },
      } : '',
    ];

    const participantsTableColumns = [
      roles[0].nombre.toLowerCase() === 'responsable de evento' ? '' : {
        title: '',
        dataIndex: 'checked',
        key: 'checked',
      },
      {
        title: 'Folio',
        dataIndex: 'folio',
        key: 'folio',
      },
      {
        title: 'CURP',
        dataIndex: 'curp',
        key: 'curp',
      },
      {
        title: 'Nombre completo',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Correo electrónico',
        dataIndex: 'email',
        key: 'email',
      },
      coreDataForm.eventData.assistants ? {
        title: 'Tipo',
        dataIndex: 'type',
        key: 'type',
      } : '',
      {
        title: 'Estatus',
        dataIndex: 'status',
        key: 'status',
      },
      {
        title: 'Acciones',
        dataIndex: 'actions',
        key: 'actions',
      },
    ];

    return {
      roles,
      participantsTableColumns,
      participantsTableColumnsCCT,
      handleDeleteParticipant,
      dataForm,
      coreDataForm,
      dataModal,
      dataSource,
      openModal,
      handleOk,
      handleCancel,
      handleChangeSearch,
      getDataFromPaginatorNumber,
      downloadReport,
      sendViewForm,
      removeAccents
    };
  },
};

export default ParticipantsComponent;
</script>
<style scoped>
.bt-option-card {
  width: 100%;
  color: #212529;
  background: none;
  border: none;
  padding-left: 15px !important;
  padding: 5px;
  font-size: 12px;
  margin-bottom: 0.5rem;
}
.bt-option-card:hover {
  cursor: pointer;
  background: #f1efef;
}
.list-data {
  margin-bottom: 0.5rem;
}
.box-event {
  border: 1px solid #bcbdbe;
  border-radius: 7px;
  padding: 0 !important;
  margin-left: 0.5rem;
  margin-bottom: 2rem;
  margin-right: 2rem;
}
.btn-delete-various {
  background-color: #FFFFFF;
  border: 1px solid #E8394D;
  border-radius: 20px;
  color: #E8394D;
  margin-bottom: 4px;
  padding: 7px;
}
.btn-add-event {
  background: #e70e4c;
  color: #fff;
  border-radius: 20px;
  border: none;
  margin-bottom: 4px;
  padding: 7px;
}
.btn-add-event:hover {
  cursor: pointer;
}
.title-more-events {
  color: #3FA7E1;
  font-size: 14px;
  font-weight: bold;
}
.title-more-events:hover {
  cursor: pointer;
}
.type-btn:hover {
  cursor: pointer;
}
.input-field {
  border-radius: 30px;
}
.btn-dropdown-actions {
  width: 32px;
}
.btn-dropdown-actions:hover {
  cursor: pointer;
}
.dropdown-actions {
  display: flex;
  flex-direction: column;
}
.dropdown-actions .btn-dropdown {
  background-color: transparent;
  border: none;
  text-align: start;
  margin-bottom: 4px;
}
.dropdown-actions .btn-dropdown:hover {
  cursor: pointer;
}
.dropdown-actions .btn-dropdown .btn-dropdown_logo {
  width: 14px;
  margin-right: 8px;
  margin-left: 4px;
}
.title-modal {
  color: #7B868C;
  font-size: 14px;
}
.modal-buttons {
  width: 100%;
  margin: 20px auto 0;
  display: flex;
  justify-content: center;
}
.modal-buttons .btn-cancel {
  background-color: #FFFFFF;
  border: 1px solid #707070;
  border-radius: 20px;
  color: #404040;
  font-size: 12px;
  padding: .3rem;
  width: 90px;
  margin: 0 5px;
}
.modal-buttons .btn-cancel:hover{
  cursor: pointer;
}
.modal-buttons .btn-accept {
  background-color: #3FA7E1;
  border: none;
  border-radius: 20px;
  color: #FFFFFF;
  font-size: 12px;
  padding: .3rem;
  width: 90px;
  margin: 0 5px;
}
.modal-buttons .btn-accept:hover {
  cursor: pointer;
}
</style>
<style>
.dropdown-participants {
  border: 1px solid #CCCCCC;
  box-shadow: 0px 3px 6px #00000029 !important;
  padding: 8px !important;
}
</style>
